import { FormattedMessage, useIntl } from "react-intl";

import { InfoPoint } from "./InfoPoint";

export const InfoSection = () => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <h2 className="text-3xl font-semibold text-textColor-highlight-default">
        <FormattedMessage
          defaultMessage="Your Hub for Better Health"
          id="wh2x7X"
        />
      </h2>
      <h3 className="mt-3 text-2xl font-semibold">
        <FormattedMessage
          defaultMessage="On your member portal, you can:"
          id="ARWuRp"
        />
      </h3>

      <ol className="my-10 space-y-8">
        <InfoPoint
          title={formatMessage({
            defaultMessage: "Access your Curative ID card",
            id: "uTtwwh",
          })}
          description={formatMessage({
            defaultMessage: "Download, print, and request a physical ID card",
            id: "3HZMn5",
          })}
          icon="/wallet_color.svg"
        />

        <InfoPoint
          title={formatMessage({
            defaultMessage: "Schedule your Baseline Visit",
            id: "G6iavf",
          })}
          description={formatMessage({
            defaultMessage:
              "Complete your Baseline Visit within 120 days of plan activation to keep your $0 out-of-pocket benefit",
            id: "RguXkx",
          })}
          icon="/calendar_color.svg"
        />

        <InfoPoint
          title={formatMessage({
            defaultMessage: "Get care and support 24/7",
            id: "xWfVg9",
          })}
          description={formatMessage({
            defaultMessage:
              "Register and get virtual urgent care, find a provider or facility near you, and contact member support",
            id: "OwkLTs",
          })}
          icon="/universal_accessibility.svg"
        />

        <InfoPoint
          title={formatMessage({
            defaultMessage: "Review your benefits",
            id: "HeU9Pc",
          })}
          description={formatMessage({
            defaultMessage:
              "View your benefits booklet and see which services are in-network",
            id: "pniwXM",
          })}
          icon="/document_color.svg"
        />
        <InfoPoint
          title={formatMessage({
            defaultMessage: "Manage prescriptions",
            id: "ThwCIi",
          })}
          description={formatMessage({
            defaultMessage:
              "View drug coverage, find in-network pharmacies, and transfer prescriptions",
            id: "0eWBTG",
          })}
          icon="/pharmacies_icon.svg"
        />
      </ol>
    </div>
  );
};
