import {
  Icon,
  IconVariant,
  SpacingVariant,
  Tooltip,
  TooltipPlacement,
  TooltipSize,
  TooltipVariant,
} from "@chp/curative_ui";
import Image from "next/image";
import { useState } from "react";

type Props = {
  description: string;
  icon: string;
  title: string;
  tooltipMessage?: string;
};

export const InfoPoint = ({
  description,
  icon,
  title,
  tooltipMessage,
}: Props) => {
  const [tooltipTriggerElement, setTooltipTriggerElement] =
    useState<HTMLElement | null>(null);

  return (
    <li className="grid grid-cols-[auto_1fr]">
      <Image alt="" width={30} height={56} src={icon} className="mt-2" />
      <div className="ml-5">
        <p className="text-xl-tall font-semibold">{title}</p>
        <p>
          {description}
          {tooltipMessage && (
            <span
              ref={setTooltipTriggerElement}
              className="mb-0.5 ml-0.5 inline-block align-sub"
            >
              <Icon
                variant={IconVariant.QUESTION_MARK_CIRCLE}
                size={SpacingVariant.S16}
              />
              <Tooltip
                triggerElement={tooltipTriggerElement}
                message={tooltipMessage}
                variant={TooltipVariant.Dark}
                placement={TooltipPlacement.BOTTOM}
                maxWidth={350}
                size={TooltipSize.Medium}
              />
            </span>
          )}
        </p>
      </div>
    </li>
  );
};
