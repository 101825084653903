import { Alert, AlertType, ButtonVariant } from "@chp/curative_ui";
import { DividerLine, DividerLineColor, FormSubmitButton } from "@chp/shared";
import { useRouter } from "next/router";
import { FormattedMessage, useIntl } from "react-intl";

import { useAuth } from "../../contexts/AuthContext";
import { ONBOARDING_ROUTE } from "../../utils/constants";
import { useLoginSection } from "./useLoginSection";

export const LoginSection = () => {
  const { isAuthenticated, errorMessage, setErrorMessage, login } = useAuth();
  const { formatMessage } = useIntl();
  const router = useRouter();
  const {
    isCreateAccountButtonLoading,
    setIsCreateAccountButtonLoading,
    isLoginButtonLoading,
    setIsLoginButtonLoading,
  } = useLoginSection();

  return (
    <div className="w-full 2xl:w-3/5">
      <h1 className="mb-4 text-3xl font-semibold">
        <FormattedMessage defaultMessage="New to Curative?" id="jQDbkV" />
      </h1>

      <p>
        <FormattedMessage
          defaultMessage="Use the link below to start creating your account and schedule your Baseline Visit today."
          id="3Eer/A"
        />
      </p>
      <FormSubmitButton
        onClick={() => {
          setIsCreateAccountButtonLoading(true);
          errorMessage && setErrorMessage(null);
          router.push(ONBOARDING_ROUTE);
        }}
        testId="CreateAccountButton"
        variant={ButtonVariant.FullWidthOutline}
        isLoading={isCreateAccountButtonLoading}
        className="!mt-4"
      >
        <FormattedMessage defaultMessage="Create an account" id="0vL5u1" />
      </FormSubmitButton>

      <DividerLine
        dividerLineColor={DividerLineColor.GRAY_486}
        className="my-4 border-DEFAULT"
      />

      <h1 className="mb-4 text-3xl font-semibold">
        <FormattedMessage defaultMessage="Welcome back!" id="0MXX5B" />
      </h1>
      <p>
        <FormattedMessage
          defaultMessage="Already a member? Login using the button below."
          id="cjlemH"
        />
      </p>
      {!isAuthenticated && errorMessage && (
        <Alert
          className="mt-4"
          ariaLive="polite"
          type={AlertType.ERROR}
          title={formatMessage({
            defaultMessage: "Error attempting to login",
            id: "AVGwrF",
          })}
          message={errorMessage}
        />
      )}
      <FormSubmitButton
        className="!mt-4"
        variant={ButtonVariant.FullWidthPrimary}
        onClick={() => {
          setIsLoginButtonLoading(true);
          errorMessage && setErrorMessage(null);
          login();
        }}
        testId="LoginButton"
        isLoading={isLoginButtonLoading}
      >
        <FormattedMessage defaultMessage="Login" id="AyGauy" />
      </FormSubmitButton>
    </div>
  );
};
