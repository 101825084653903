import { useState } from "react";

export const useLoginSection = () => {
  const [isCreateAccountButtonLoading, setIsCreateAccountButtonLoading] =
    useState(false);
  const [isLoginButtonLoading, setIsLoginButtonLoading] = useState(false);

  return {
    isCreateAccountButtonLoading,
    setIsCreateAccountButtonLoading,
    isLoginButtonLoading,
    setIsLoginButtonLoading,
  };
};
