import { NavbarLink } from "@chp/curative_ui";
import {
  ABOUT_US_URL,
  FIND_A_PROVIDER_URL,
  FOR_MEMBERS_URL,
  FormattedNavbar,
  FORMULARY_SEARCH_URL,
  I18nContext,
  MEMBER_PORTAL_HOMEPAGE_URL,
} from "@chp/shared";
import { useContext, useMemo } from "react";
import { useIntl } from "react-intl";

export const HomePageNav = () => {
  const { renderLocalePicker } = useContext(I18nContext);
  const { formatMessage } = useIntl();

  const links: NavbarLink[] = useMemo(() => {
    return [
      {
        key: "why_curative",
        text: formatMessage({ defaultMessage: "Why Curative", id: "OeZgbm" }),
        href: ABOUT_US_URL,
      },
      {
        key: "for_members",
        text: formatMessage({ defaultMessage: "For Members", id: "ifH+8m" }),
        href: FOR_MEMBERS_URL,
      },
      {
        key: "find_a_doctor",
        text: formatMessage({ defaultMessage: "Find a Doctor", id: "J9ecA+" }),
        href: FIND_A_PROVIDER_URL,
      },
      {
        key: "find_a_medication",
        text: formatMessage({
          defaultMessage: "Find a Medication",
          id: "ZxIELY",
        }),
        href: FORMULARY_SEARCH_URL,
      },
    ];
  }, [formatMessage]);

  return (
    <FormattedNavbar
      logoHref={MEMBER_PORTAL_HOMEPAGE_URL}
      links={links}
      renderLocalePicker={renderLocalePicker}
    />
  );
};
