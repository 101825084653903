import { SpacingVariant, Spinner } from "@chp/curative_ui";

const LoadingIndicator = ({
  testId = "LoadingIndicator",
}: {
  testId?: string;
}) => {
  return (
    <div className="mt-16 flex flex-col items-center" data-testid={testId}>
      <Spinner size={SpacingVariant.S64} />
    </div>
  );
};

export default LoadingIndicator;
