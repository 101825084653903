import {
  DegradedPerformanceAlert,
  DownErrorMessageWithLogo,
  LaunchDarklyFlags,
  NextPageWithLayout,
  NonProdBanner,
} from "@chp/shared";
import { IS_NON_PROD_ENV } from "@chp/shared/env";
import { useFlags } from "launchdarkly-react-client-sdk";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactElement, useEffect } from "react";
import { useIntl } from "react-intl";

import LoadingIndicator from "~/components/LoadingIndicator";
import { useAuth } from "~/contexts/AuthContext";
import { DASHBOARD_ROUTE, ONBOARDING_ROUTE } from "~/utils/constants";

import {
  HomePageNav,
  InfoSection,
  LoginSection,
} from "../components/LoginPage";

export const HomePage: NextPageWithLayout = () => {
  const flags = useFlags<LaunchDarklyFlags>();
  const router = useRouter();
  const { hasCompletedOnboarding, isAuthenticated, isLoading } = useAuth();
  const { formatMessage } = useIntl();

  const showNonProdBanner = flags.showNonProductionBanner && IS_NON_PROD_ENV;

  useEffect(() => {
    if (!isAuthenticated) return;

    router.push(hasCompletedOnboarding ? DASHBOARD_ROUTE : ONBOARDING_ROUTE);
  }, [router, isAuthenticated, hasCompletedOnboarding]);

  if (flags.enableMemberPortalDownScreen) {
    return (
      <main className="mt-10">
        <DownErrorMessageWithLogo />
      </main>
    );
  }

  return (
    <>
      <Head>
        <title>
          {formatMessage({
            defaultMessage: "Welcome Back | Curative Member Portal",
            id: "V+x7Uv",
          })}
        </title>
        <meta name="og:title" content="Curative Member Portal" />
        <meta
          name="description"
          content="Welcome to the Curative health plan member portal. Our goal is health, not headaches. Find everything you need regarding your plan in this portal."
        />
      </Head>

      <main className="flex h-full flex-col">
        {showNonProdBanner && (
          <aside>
            <NonProdBanner />
          </aside>
        )}
        {flags.enableMemberPortalDegradedPerformanceBanner && (
          <div className="-mb-16 mt-16 md:-mb-20 md:mt-20">
            <DegradedPerformanceAlert />
          </div>
        )}
        <div className="flex h-full flex-row">
          <div className="flex w-full px-10 py-32 md:justify-end md:px-20">
            {isLoading || isAuthenticated ? (
              <div className="w-full 2xl:w-3/5">
                <LoadingIndicator />
              </div>
            ) : (
              <LoginSection />
            )}
          </div>
          <div className="hidden h-screen w-full bg-gray-100 px-20 py-32 md:block">
            <InfoSection />
          </div>
        </div>
      </main>
    </>
  );
};

HomePage.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <HomePageNav />
      {page}
    </>
  );
};

export default HomePage;
